const navItems = [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'APP_DASHBOARD',
    action: 'read',
  },
  {
    title: 'Müşteri Kartları',
    route: 'Customers',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'CustomerAdd',
        resource: 'ADMIN_CUSTOMER_ADD',
        action: 'read',
      },
      {
        title: 'Listele',
        route: 'Customers',
        resource: 'ADMIN_CUSTOMER_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Projeler',
    route: 'Projects',
    icon: 'GithubIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'ProjectCustomerSearch',
        resource: 'ADMIN_PROJECT_ADD',
        action: 'read',
      },
      {
        title: 'Listele',
        route: 'Projects',
        resource: 'ADMIN_PROJECT_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Görevler',
    route: '#',
    icon: 'ToggleRightIcon',
    children: [
      {
        title: 'Oluştur',
        route: '#',
        resource: 'ADMIN_TASK_ADD',
        action: 'read',
      },
      {
        title: 'Listele',
        route: '#',
        resource: 'ADMIN_TASK_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Destek Talepleri',
    route: 'Supports',
    icon: 'LifeBuoyIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'SupportCustomerSearch',
        resource: 'ADMIN_REMINDER_LIST',
        action: 'read',
      },
      {
        title: 'Listele',
        route: 'Supports',
        resource: 'ADMIN_REMINDER_LIST',
        action: 'read',
      },
    ],
  },
  {
    header: 'Yönetim',
    resource: 'ADMIN',
    action: 'read',
  },
  {
    title: 'Tanımlamalar',
    route: '#',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Ülke',
        route: 'Countries',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Şehir',
        route: 'Cities',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Temas Tipi',
        route: 'Meets',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Sektör',
        route: 'Sectors',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Müşteri Tipleri',
        route: 'CustomerTypes',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Görev Tipleri',
        route: 'TaskTypes',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Görev Durumları',
        route: 'TaskStatuses',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
    ],
  },
  {
    title: 'Kullanıcılar',
    route: 'Users',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Kullanıcılar',
        route: 'Users',
        resource: 'ADMIN_USERS',
        action: 'read',
      },
      {
        title: 'Kullanıcı Yetkileri',
        route: 'UserTypes',
        resource: 'ADMIN_USER_TYPES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Ayarlar',
    route: '#',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'SMTP',
        route: 'Smtp',
        resource: 'ADMIN_SMTP',
        action: 'read',
      },
    ],
  },
]
export default navItems
