const navItems = [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'APP_DASHBOARD',
    action: 'read',
  },
  {
    title: 'Destek',
    route: 'ClientSupports',
    icon: 'LifeBuoyIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'ClientSupportAdd',
        resource: 'CLIENT_SUPPORT_ADD',
        action: 'read',
      },
      {
        title: 'Listele',
        route: 'ClientSupports',
        resource: 'CLIENT_SUPPORT_LIST',
        action: 'read',
      },
    ],
  },
]
export default navItems
