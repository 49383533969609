<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="!userData.id_customers? navMenuItems : navMenuItemsClient" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import navMenuItemsClient from '@/navigation/horizontal/client'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  setup() {
    return {
      navMenuItems,
      navMenuItemsClient,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
